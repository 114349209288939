export function filterImageLink(link, smallEnd = this.$common.K_QINIU_IMAGE_SMALL_END_100_PX) {
    if (!link) return link;
    const validExtensions = ['.jpg', '.jpeg', '.png'];
    
    // 检查链接的文件扩展名是否合法
    const extension = link.substring(link.lastIndexOf('.'));
    if (!validExtensions.includes(extension.toLowerCase())) {
      return link;
    }
    return link + smallEnd;
  }
  