<template>
  <div id="globalPartnersDetail" v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="全球合作伙伴 - 伙伴详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveCategoryModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="global_parent_model"
          :rules="rules"
          ref="global_parent_model"
          label-width="100px"
        >
          <el-form-item label="产品:" prop="product_id">
            <el-select
              v-model="global_parent_model.product_id"
              placeholder="请选择"
              loading-text="加载中..."
              @change="stateSelectOnclick"
            >
              <el-option
                v-for="item in product_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属大州:" prop="state_id">
            <el-select
              v-model="global_parent_model.state_id"
              placeholder="请选择"
              loading-text="加载中..."
              @change="stateSelectOnclick"
            >
              <el-option
                v-for="item in state_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属地区:" prop="region_id">
            <el-select
              v-model="global_parent_model.region_id"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in region_list_map.get(global_parent_model.state_id)
                  ? region_list_map.get(global_parent_model.state_id)
                  : []"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称:" prop="name">
            <el-input v-model="global_parent_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number v-model="global_parent_model.number" :min="0" label="权重"></el-input-number>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="global_parent_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电话:">
            <el-input v-model="global_parent_model.tel" clearable></el-input>
          </el-form-item>
          <el-form-item label="邮件:">
            <el-input v-model="global_parent_model.email" clearable></el-input>
          </el-form-item>
          <el-form-item label="网址:">
            <el-input v-model="global_parent_model.web_url" clearable></el-input>
          </el-form-item>
          <el-form-item style="text-align: left" label="PC端图标:">
            <el-input v-model="global_parent_model.pc_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px; margin-top: 10px;"
                  :src="filterImageLink(global_parent_model.pc_image_url, $common.K_QINIU_IMAGE_SMALL_END_100_PX)"
                  :preview-src-list="[global_parent_model.pc_image_url]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="text-align: left" label="WAP端图标:">
            <el-input v-model="global_parent_model.wap_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="filterImageLink(global_parent_model.wap_image_url, $common.K_QINIU_IMAGE_SMALL_END_100_PX)"
                  :preview-src-list="[global_parent_model.wap_image_url]"
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <CopyPaste
            :button_status="
              this.global_parent_id !== undefined && this.global_parent_id.length > 0
            "
            @copyInfo="copyInfo(global_parent_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="globalParentsFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../../components/CopyPaste.vue";
import { filterImageLink } from "@/utils";

export default {
  name: "GlobalPartnersDetailView",
  components: {
    CopyPaste,
    FileChooseDrawerModule
  },
  data() {
    return {
      filterImageLink,
      loading: false,
      state_list: [],
      region_list_map: {},
      global_parent_id: "",
      global_parent_model: {
        id: "",
        state_id: "",
        region_id: "",
        name: "",
        number: 0,
        pc_image_url: "",
        wap_image_url: "",
        tel: "",
        web_url: "",
        email: "",
        status: 2,
        product_id: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ],
        product_id: [
          { required: true, message: "请选择产品", trigger: "change" }
        ],
        state_id: [{ required: true, message: "请悬着州", trigger: "change" }],
        region_id: [
          { required: true, message: "请选择地区", trigger: "change" }
        ]
      },
      product_list: []
    };
  },
  created() {
    this.region_list_map = new Map();
    this.getStateList();
    this.getProductList();
    this.global_parent_id = this.$route.query.id;
    if (
      this.global_parent_id !== undefined &&
      this.global_parent_id.length > 0
    ) {
      this.getRegionModel();
    }
  },
  methods: {
    getStateList() {
      let _me = this;
      let params = {
        status: 1
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/state/find/tree", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.state_list = res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.state_list) {
              _me.region_list_map.set(i.id, i.region_list);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getProductList() {
      let _me = this;
      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/product/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.product_list =
              res.data.array === undefined ? [] : res.data.array;
            // for (let i of _me.state_list) {
            //   _me.region_list_map.set(i.id, i.region_list);
            // }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getRegionModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.global_parent_id
      };
      _me.$common
        .httpPost("/api/brand/global_partners/admin/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.global_parent_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    saveCategoryModel() {
      if (
        this.global_parent_id !== undefined &&
        this.global_parent_id.length > 0
      ) {
        this.updateCategoryModel();
      } else {
        this.newCategoryModel();
      }
    },
    updateCategoryModel() {
      let _me = this;
      let params = {
        id: _me.global_parent_model.id,
        state_id: _me.global_parent_model.state_id,
        region_id: _me.global_parent_model.region_id,
        product_id: _me.global_parent_model.product_id,
        name: _me.global_parent_model.name,
        number: _me.global_parent_model.number,
        pc_image_url: _me.global_parent_model.pc_image_url,
        wap_image_url: _me.global_parent_model.wap_image_url,
        tel: _me.global_parent_model.tel,
        web_url: _me.global_parent_model.web_url,
        email: _me.global_parent_model.email,
        status: _me.global_parent_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newCategoryModel() {
      let _me = this;
      let params = {
        state_id: _me.global_parent_model.state_id,
        region_id: _me.global_parent_model.region_id,
        product_id: _me.global_parent_model.product_id,
        name: _me.global_parent_model.name,
        number: _me.global_parent_model.number,
        pc_image_url: _me.global_parent_model.pc_image_url,
        wap_image_url: _me.global_parent_model.wap_image_url,
        tel: _me.global_parent_model.tel,
        web_url: _me.global_parent_model.web_url,
        email: _me.global_parent_model.email,
        status: _me.global_parent_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    stateSelectOnclick(value) {
      this.region_id = "";
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.globalParentsFileChooseDrawerModule.showChooseDrawer(function(
        chooseFileList
      ) {
        console.log("接收到选择的文件:", chooseFileList);
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_image_url":
              _me.global_parent_model.pc_image_url = chooseFileList[0].file_url;
              break;
            case "wap_image_url":
              _me.global_parent_model.wap_image_url =
                chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.state_list.forEach(item => {
        if (item.id == str.state_id) {
          str.state_name = item.name;
          item.region_list.forEach(ele => {
            if (ele.id == str.region_id) {
              str.region_name = ele.name;
            }
          });
        }
      });
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.global_parent_model = model;
      this.global_parent_model.id = this.global_parent_id;
      this.state_list.forEach(item => {
        if (item.name == model.state_name) {
          this.global_parent_model.state_id = item.id;
          item.region_list.forEach(ele => {
            if (ele.name == model.region_name) {
              this.global_parent_model.region_id = ele.id;
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>

<style>
#globalPartnersDetail .g-image .el-image__preview {
  object-fit: contain;
}
</style>
