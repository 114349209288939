import { render, staticRenderFns } from "./GlobalPartnersRegionListView.vue?vue&type=template&id=45f0f4fb&scoped=true&"
import script from "./GlobalPartnersRegionListView.vue?vue&type=script&lang=js&"
export * from "./GlobalPartnersRegionListView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f0f4fb",
  null
  
)

export default component.exports